import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navigate from '../../../../../util/Navigate';

const ScheduleNavbar = ({ schedulerId }) => {
    const location = useLocation();

    // Function to check if the current route matches the given path
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav small">
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.schedulerViewPage(schedulerId))}`} to={Navigate.schedulerViewPage(schedulerId)}>
                                <i className="fas fa-eye"></i> Overview
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.schedulerUpdatePage(schedulerId))}`} to={Navigate.schedulerUpdatePage(schedulerId)}>
                                <i className="fas fa-edit"></i> Edit
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.schedulerUpdatesListPage(schedulerId))}`} to={Navigate.schedulerUpdatesListPage(schedulerId)}>
                                <i className="fas fa-keyboard"></i>  Updates
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.schedulerPostsPage(schedulerId))}`} to={Navigate.schedulerPostsPage(schedulerId)}>
                                <i className="fas fa-thumbs-up"></i> Social Posts
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.schedulerUsersPage(schedulerId))}`} to={Navigate.schedulerUsersPage(schedulerId)}>
                                <i className="fas fa-users"></i> Users
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default ScheduleNavbar;
