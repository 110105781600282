// src/pages/scheduler/SchedulerListSocialMediaPostsPage.jsx

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import SocialMediaPostItem from '../../component/section/social/post_item';

const SchedulerListSocialMediaPostsPage = () => {
    const [scheduler, setScheduler] = useState({});
    const [posts, setPosts] = useState([]);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [filters, setFilters] = useState({
        socialPlatform: '',
        status: '',
        order: 'desc',
    });

    const navigate = useNavigate();

    const platforms = [
        { name: 'Facebook', icon: faFacebook, value: 'facebook' },
        { name: 'Instagram', icon: faInstagram, value: 'instagram' },
        { name: 'Twitter', icon: faTwitter, value: 'twitter' },
        { name: 'TikTok', icon: faTiktok, value: 'tiktok' },
        { name: 'Discord', icon: faDiscord, value: 'discord' },
        { name: 'Reddit', icon: faReddit, value: 'reddit' },
        { name: 'YouTube', icon: faYoutube, value: 'youtube' },
        { name: 'Telegram', icon: faTelegram, value: 'telegram' },
        { name: 'Bluesky', icon: faKickstarter, value: 'bluesky' }, // Placeholder icon
    ];

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        } else {
            setCurrentUserId(Glitch.util.Session.getID());
            fetchSchedulerAndPosts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, filters]);

    const fetchSchedulerAndPosts = () => {
        setIsLoading(true);

        Glitch.api.Scheduler.getSchedule(id)
            .then(response => {
                setScheduler(response.data.data);

                const queryParams = {
                    title_promotion_schedule_id: id,
                };

                if (filters.socialPlatform) {
                    queryParams.social_platform = filters.socialPlatform;
                }

                if (filters.status) {
                    queryParams.status = filters.status;
                }

                // Order by created_at
                queryParams.order_by = 'created_at';
                queryParams.order_direction = filters.order;

                Glitch.api.SocialMediaPosts.list(queryParams)
                    .then(res => {
                        setPosts(res.data.data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                    });
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        setFilters({
            ...filters,
            [name]: value,
        });
    };

    if (isLoading) {
        return (
            <>
                <PublisherHeader position={"relative"} />
                <div className="container my-5 text-center">
                    <Loading />
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
                    { name: 'Social Media Posts', link: Navigate.schedulerPostsPage(scheduler?.id) }
                ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">{scheduler?.name || 'Scheduler'} Social Media Posts</h2>
                        <p className="lead">View all social media posts generated by {scheduler?.title?.name}.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <ScheduleNavbar schedulerId={id} />
            </div>

            <div className="container my-5">
                {/* Filters */}
                <div className="card mb-4">
                    <div className="card-header">
                        <h4 className="text-black">Filters</h4>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                {/* Social Platform Filter */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="socialPlatform">Social Platform</label>
                                        <select
                                            className="form-control"
                                            id="socialPlatform"
                                            name="socialPlatform"
                                            value={filters.socialPlatform}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">All Platforms</option>
                                            {platforms.map(platform => (
                                                <option key={platform.value} value={platform.value}>
                                                    {platform.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {/* Status Filter */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="status">Status</label>
                                        <select
                                            className="form-control"
                                            id="status"
                                            name="status"
                                            value={filters.status}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">All Statuses</option>
                                            <option value="pending">Pending</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="posted">Posted</option>
                                            <option value="failed">Failed</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Order */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="order">Order</label>
                                        <select
                                            className="form-control"
                                            id="order"
                                            name="order"
                                            value={filters.order}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="desc">Newest First</option>
                                            <option value="asc">Oldest First</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* List of Social Media Posts */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4 className="text-black">Social Media Posts</h4>
                    </div>
                    <div className="card-body">
                        {posts.length > 0 ? (
                            posts.map(post => (
                                <SocialMediaPostItem
                                    key={post.id}
                                    post={post}
                                    currentUserId={currentUserId}
                                />
                            ))
                        ) : (
                            <p>No social media posts available.</p>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default SchedulerListSocialMediaPostsPage;
