import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/scss/bootstrap.scss';
import './assets/css/icofont.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/brackets.css';

import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Swiper from 'swiper';
import 'swiper/css';
import ScrollToTop from "./component/layout/scrolltop";

import ErrorPage from "./pages/misc/errorpage";
import HomeTwo from './pages/misc/hometwo';
import PrivacyPage from "./pages/misc/privacy";
import TermsPage from "./pages/misc/terms";
import ContactPage from "./pages/misc/contact";
import DataRemovalPage from "./pages/misc/dataremoval";
import GDPRPage from "./pages/misc/gdpr";
import AccessDeniedPage from "./pages/misc/accessdenied";
import BenefitsPage from './pages/misc/benefits';
import CreatorsPage from './pages/misc/creators';

import AuthComplete from "./pages/auth/auth_complete";
import AuthFacebook from "./pages/auth/auth_facebook";
import AuthTwitch from "./pages/auth/auth_twitch";
import AuthTikTok from "./pages/auth/auth_tiktok";
import AuthYoutube from "./pages/auth/auth_youtube";
import AuthStripe from "./pages/auth/auth_stripe";
import AuthGoogle from "./pages/auth/auth_google";
import AuthMicrosoft from "./pages/auth/auth_microsoft";
import AuthMicrosoftTeams from "./pages/auth/auth_teams";
import SignUp from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotpassword";
import ResetPassword from "./pages/auth/resetpassword";
import LogIn from "./pages/auth/login";
import RegisterStep2 from './pages/auth/register_step2';

import WatchRecording from './pages/streams/watchrecording';

// Tips
import EmojisPage from './pages/tips/emojis';
import EmojisWatchPage from './pages/tips/watch';

// Communities
import CommunitiesList from './pages/communities/CommunitiesList';
import CommunitiesCreate from './pages/communities/CommunitiesCreate';
import CommunitiesView from './pages/communities/CommunitiesView';
import CommunitiesManage from './pages/communities/CommunitiesManage';
import CommunitiesUpdate from './pages/communities/CommunitiesUpdate';
import CommunitiesFeatures from './pages/communities/CommunitiesFeatures';
import CommunitiesMedia from './pages/communities/CommunitiesMedia';
import CommunitiesSocial from './pages/communities/CommunitiesSocial';
import CommunitiesCname from './pages/communities/CommunitiesCname';
import CommunitiesAccessibility from './pages/communities/CommunitiesAccessibility';
import CommunitiesUsers from './pages/communities/CommunitiesUsers';
import CommunitiesUsersInvite from './pages/communities/CommunitiesUsersInvite';
import CommunityContractsPage from './pages/communities/CommunitiesContracts';
import CommunitiesEmailTemplatesPage from './pages/communities/CommunitiesEmailTemplates';

import AccountUpdatePage from "./pages/account/profile";
import AccountVerifyPage from "./pages/account/verify";

import Navigate from "../../util/Navigate";
import MarketingPage from './pages/misc/marketing';
import RevenuePage from './pages/misc/revenue';
import FeaturesPage from './pages/misc/features';
import CreatorsRewardsPage from './pages/misc/creatorsrewards';
import CreatorsPublishersPage from './pages/misc/creatorspublishers';
import InstallationPage from './pages/misc/installation';
import CommunitiesCss from './pages/communities/CommunitiesCss';
import AboutPage from './pages/misc/about';

import CreatorsOverviewPage from './pages/creators/CreatorsOverview';
import CreatorsMicroPage from './pages/creators/CreatorsMirco';
import CreatorsBlackPage from './pages/creators/CreatorsBlack';
import CreatorsInfluencerMacPage from './pages/misc/creatorsinfluencersmac';
import CreatorsInfluencerLinuxPage from './pages/misc/creatorsinfluencerslinux';
import CreatorsPublishersMacPage from './pages/misc/creatorspublishersmac';
import CreatorsPublishersLinuxPage from './pages/misc/creatorspublisherslinux';
import CreatorsCalculator from './pages/misc/calculator';

import CampaignsListPage from './pages/campaigns/CampaignsListPage';
import CampaignCreatePage from './pages/campaigns/CampaignCreatePage';
import CampaignUpdatePage from './pages/campaigns/CampaignUpdatePage';
import CampaignsViewPage from './pages/campaigns/CampaignsViewPage';
import CampaignsFindInfluencersPage from './pages/campaigns/CampaignsFindInfluencersPage';
import InfluencerCampaignsPage from './pages/influencers/InfluencerCampaignsPage';
import InfluencerFindCampaignsPage from './pages/influencers/InfluencerFindCampaignsPage';
import InfluencerViewCampaignPage from './pages/influencers/InfluencerViewCampaignPage';
import InfluencerManageCampaignPage from './pages/influencers/InfluencerManageCampaignPage';
import InfluencerMyCampaignsPage from './pages/influencers/InfluencerMyCampaignsPage';

import MessagesListPage from './pages/messages/MessagesListPage';
import MessagesCreatePage from './pages/messages/MessagesCreatePage';
import MessagesReadPage from './pages/messages/MessagesReadPage';

import PublishersGamificationPage from './pages/publishers/publishers_gamification';
import PublishersOptimizationPage from './pages/publishers/publishers_optomization';
import PublisherBenefitPage from './pages/publishers/publishers_benefits';
import PublisherUserAcquistion from './pages/publishers/publishers_user_acquistion';
import PublisherRegisterPage from './pages/publishers/publishers_register';
import Cohostwatchstream from './pages/streams/cohostwatchstream';
import PublisherPilotsPage from './pages/publishers/publishers_pilots';
import CampaignsViewCreatorPage from './pages/campaigns/CampaignsViewCreatorPage';
import CampaignsViewCreatorPerformancePage from './pages/campaigns/CampaignsViewCreatorPerformancePage';

import PublisherPricingPage from './pages/publishers/publishers_pricing';
import CampaignsMessageInfluencerPage from './pages/campaigns/CampaignsMessageInfluencerPage';
import CommunitiesAdminList from './pages/communities/CommunitiesAdminList';
import PublisherOnboardinStep1Page from './pages/publishers/publisher_onboarding_step_1';
import PublisherOnboardinStep2Page from './pages/publishers/publisher_onboarding_step_2';
import PublisherOnboardingStep3Page from './pages/publishers/publisher_onboarding_step_3';
import CampaignsStartPage from './pages/campaigns/CampaignsStartPage';
import CreatorOnboardinStep1Page from './pages/creators/creator_onboarding_step_1';
import CreatorOnboardinStep2Page from './pages/creators/creator_onboarding_step_2';
import CreatorOnboardinStep3Page from './pages/creators/creator_onboarding_step_3';
import CreatorOnboardinStep4Page from './pages/creators/creator_onboarding_step_4';
import AuthReddit from './pages/auth/auth_reddit';
import AuthTwitter from './pages/auth/auth_twitter';
import CampaignsViewInfluencerPage from './pages/campaigns/CampaignsViewInfluencerPage';
import InfluencerViewCampaignInvitePage from './pages/influencers/InfluencerViewCampaignInvitePage';
import PublisherSubscribePage from './pages/publishers/publishers_subscribe';
import CommunitiesCreditCardsPage from './pages/communities/CommunitiesCreditCards';
import CreatorOnboardinStep5Page from './pages/creators/creator_onboarding_step_5';
import PublisherMessagesCreatePage from './pages/publishers/publisher_messages_create_page';
import PublisherMessagesListPage from './pages/publishers/publisher_messages_list_page';
import PublisherMessagesReadPage from './pages/publishers/publisher_messages_read_page';
import InfluencerMessagesCreatePage from './pages/influencers/InfluencerMessagesCreatePage';
import InfluencerMessagesListPage from './pages/influencers/InfluencerMessagesListPage';
import InfluencerMessagesReadPage from './pages/influencers/InfluencerMessagesReadPage';
import CampaignsInvitePage from './pages/campaigns/CampaignsInvitePage';
import InfluencerPayoutsPage from './pages/influencers/InfluencerPayoutsPage';
import InfluencerInvitesPage from './pages/influencers/InfluencerInvitesPage';
import CampaignUpdateInfluencerPage from './pages/campaigns/CampaignUpdateInfluencerPage';
import CampaignsLedgerPage from './pages/campaigns/CampaignsLedgerPage';
import PublishersDatabasePage from './pages/publishers/publishers_database';
import CampaignsRecommendedInfluencersPage from './pages/campaigns/CampaignsRecommendedInfluencersPage';
import CampaignsContentPage from './pages/campaigns/CampaignsContentPage';
import InfluencerBeginCampaignPage from './pages/influencers/InfluencerBeginCampaignPage';
import PublishersAIPage from './pages/publishers/publishers_ai';
import CreatorsEditingToolPage from './pages/creators/CreatorsEditing';
import CreatorsFeaturesPage from './pages/creators/CreatorsFeatures';
import InfluencerCampaignApplyPage from './pages/influencers/InfluencerCampaignApplyPage';
import InfluencerDownloadAppPage from './pages/influencers/InfluencerDownloadAppPage';
import InfluencerGettingStartedPage from './pages/influencers/InfluencerGettingStartedPage';
import CampaignsStatisticsPage from './pages/campaigns/CampaignsStatisticsPage';
import CampaignsUpdateInfluencerInviteCompensationPage from './pages/campaigns/CampaignsUpdateInfluencerInviteCompensationPage';
import CreatorsGettingStarted from './pages/creators/CreatorsGettingStarted';
import CrawlPage from './pages/misc/crawl';
import PublishersCRMPage from './pages/publishers/publishers_crm';
import CreatorsMulticatingPage from './pages/creators/CreatorsMulticasting';
import CreatorsTiktokStreamPage from './pages/creators/CreatorsTiktokStream';
import CreatorsOptimizationPage from './pages/creators/CreatorsOptimization';
import CampaignUpdateMediaPage from './pages/campaigns/CampaignUpdateMediaPage';
import CampaignUpdateLinksPage from './pages/campaigns/CampaignUpdateLinksPage';
import CampaignsInfluencersPage from './pages/campaigns/CampaignsInfluencersPage';
import CampaignsViewInfluencerInvitePage from './pages/campaigns/CampaignsViewInfluencerInvitePage';
import PublisherGamePricingPage from './pages/publishers/publishers_tools_pricing';
import AuthDocusign from './pages/auth/auth_docusign';
import AuthSimplesign from './pages/auth/auth_simplesign';
import CampaignsUpdateInfluencerInviteContractPage from './pages/campaigns/CampaignsUpdateInfluencerInviteContractPage';
import CampaignsViewCreatorContractPage from './pages/campaigns/CampaignsViewCreatorContractPage';
import CampaignsInviteInfluencerPage from './pages/campaigns/CampaignsInviteInfluencerPage';
import PublisherTutorialInvitePipelinesPage from './pages/publishers/publisher_tutorial_invite_pipelines';
import PublisherTutorialCampaignsCreatePage from './pages/publishers/publisher_tutorial_campaigns_create';
import PublisherTutorialInviteDatabasePage from './pages/publishers/publisher_tutorial_invite_database';
import PublisherTutorialInviteRecommendedPage from './pages/publishers/publisher_tutorial_invite_recommended';
import PublisherTutorialInvitesPage from './pages/publishers/publisher_tutorial_invite_invite';
import PublisherTutorialInviteCustomEmailsPage from './pages/publishers/publisher_tutorial_invite_custom_emails';
import PublisherTutorialInviteNotesPage from './pages/publishers/publisher_tutorial_invite_notes';
import PublisherTutorialsPage from './pages/publishers/publisher_tutorials';
import PublisherTutorialCampaignsChecklistPage from './pages/publishers/publisher_tutorial_campaigns_checklist';
import PublisherTutorialCampaignsOverviewPage from './pages/publishers/publisher_tutorial_campaigns_overview';
import PublisherTutorialInviteNegotiationsPage from './pages/publishers/publisher_tutorial_invite_negotiations';
import CommunitiesGettingStarted from './pages/communities/CommunitiesGettingStarted';
import PublisherTutorialCampaignsRateCardPage from './pages/publishers/publisher_tutorial_campaigns_ratecard';
import CampaignsViewCreatorPaymentPage from './pages/campaigns/CampaignsViewCreatorPaymentPage';
import InfluencerTutorialsPage from './pages/influencers/InfluencerTutorialsPage';
import InfluencerTutorialsContentPage from './pages/influencers/InfluencerTutorialsContentPage';
import InfluencerTutorialsInvitesPage from './pages/influencers/InfluencerTutorialsInvitesPage';
import InfluencerTutorialsGamesPage from './pages/influencers/InfluencerTutorialsGamesPage';
import CommunitiesUsersManage from './pages/communities/CommunitiesUsersManage';
import PublisherGamePRPage from './pages/publishers/publishers_tools_pr';
import PublisherGameFreeToolsPage from './pages/publishers/publishers_tools';
import PublisherGameForumsPage from './pages/publishers/publishers_tools_forums';
import PublisherGameWikiPage from './pages/publishers/publishers_tools_wiki';
import PublisherGamDatabasePage from './pages/publishers/publishers_tools_databases';
import PublisherGameInfluencersPage from './pages/publishers/publishers_tools_influencers';
import GameShowsListPage from './pages/gameshows/GameShowsListPage';
import GameShowsCreatePage from './pages/gameshows/GameShowsCreatePage';
import GameShowsViewPage from './pages/gameshows/GameShowsViewPage';
import GameShowsUpdatePage from './pages/gameshows/GameShowsUpdatePage';
import PublisherGameShowsPage from './pages/publishers/publishers_tools_gameshows';
import PublisherGameGuidePage from './pages/publishers/publishers_tools_guide';
import NewslettersListPage from './pages/newsletters/NewslettersListPage';
import NewslettersCreatePage from './pages/newsletters/NewslettersCreatePage';
import NewsletterViewPage from './pages/newsletters/NewsletterViewPage';
import NewsletterCampaignCreatePage from './pages/newsletters/NewsletterCampaignCreatePage';
import NewsletterCampaignSendPage from './pages/newsletters/NewsletterCampaignSendPage';
import NewsletterCampaignUpdatePage from './pages/newsletters/NewsletterCampaignUpdatePage';
import NewsletterCampaignViewPage from './pages/newsletters/NewsletterCampaignViewPage';
import NewsletterUpdatePage from './pages/newsletters/NewsletterUpdatePage';
import NewsletterSubscribersPage from './pages/newsletters/NewsletterSubscribersPage';
import NewsletterSubscribePage from './pages/newsletters/NewsletterSubscribePage';
import NewsletterImportPage from './pages/newsletters/NewsletterImportPage';
import GameShowsPromotePage from './pages/gameshows/GameShowsPromotePage';
import CreatorsPlayTestersPage from './pages/creators/CreatorPlayTesters';
import MediakitListPage from './pages/mediakits/MediakitListPage';
import MediakitCreatePage from './pages/mediakits/MediakitCreatePage';
import MediakitUpdatePage from './pages/mediakits/MediakitUpdatePage';
import MediakitViewPage from './pages/mediakits/MediakitViewPage';
import MediakitStandalonePage from './pages/mediakits/MediaKitStandAlone';
import MediakitSharePage from './pages/mediakits/MediakitSharePage';
import PublisherOnboardingStep4Page from './pages/publishers/publisher_onboarding_step_4';
import PublishersContentPage from './pages/publishers/publishers_content';
import PublishersHolidayPage from './pages/publishers/publisher_holiday_page';
import PublisherGameNewsletterPage from './pages/publishers/publishers_tools_mailers';
import PlayTestGamesListPage from './pages/testers/PlayTestGamesListPage';
import PlayTestGamesCreatePage from './pages/testers/PlayTestGamesCreatePage';
import PlayTestsListPage from './pages/testers/PlayTestsListPage';
import PlayTestDetailsPage from './pages/testers/PlayTestDetailsPage';
import PlayTestManageQuestionsPage from './pages/testers/PlayTestManageQuestionsPage';
import PlayTestInviteTestersPage from './pages/testers/PlayTestInviteTestersPage';
import PlayTestGamesUpdatePage from './pages/testers/PlayTestGamesUpdatePage';
import PlayTestFindGamesToTestPage from './pages/players/PlayTestFindGamesToTestPage';
import PlayTestMyPlayTestsPage from './pages/players/PlayTestMyPlayTestsPage';
import PlayTestDetailsForTesterPage from './pages/players/PlayTestDetailsForTesterPage';
import PlayTestSubmitPlayTestAnswersPage from './pages/players/PlayTestSubmitPlayTestAnswersPage';
import PlayTestReviewTestPage from './pages/testers/PlayTestReviewTestPage';
import PlayTestGameDetailsPage from './pages/players/PlayTestGameDetailsPage';
import PublishersPlayersPage from './pages/publishers/publishers_players_page';
import PublishersNewsletterPage from './pages/publishers/publishers_newsletter_page';
import PublishersShowcasesPage from './pages/publishers/publisher_showcases_page';
import PublishersMediaKitPage from './pages/publishers/publishers_mediakit_page';
import PlayTestGamesPreviewPage from './pages/testers/PlayTestGamesPreviewPage';
import CommunitiesNewsletter from './pages/communities/CommunitiesNewsletter';
import PlayTestMessagePage from './pages/testers/PlayTestMessagePage';
import SchedulerListPage from './pages/scheduler/SchedulerListPage';
import SchedulerCreatePage from './pages/scheduler/SchedulerCreatePage';
import SchedulerSocialPage from './pages/scheduler/SchedulerSocialPage';
import SchedulerUpdatePage from './pages/scheduler/SchedulerUpdatePage';
import SchedulerUpdateUpdatePage from './pages/scheduler/SchedulerUpdateUpdatePage';
import SchedulerCreateUpdatePage from './pages/scheduler/SchedulerCreateUpdatePage';
import SchedulerViewPage from './pages/scheduler/SchedulerViewPage';
import SchedulerListUpdatesPage from './pages/scheduler/SchedulerListUpdatesPage';
import SchedulerListSocialMediaPostsPage from './pages/scheduler/SchedulerListSocialMediaPostsPage';

const LiveChatWidget = lazy(() => import("@livechat/widget-react").then(module => ({ default: module.LiveChatWidget })));

function Template() {
  const [loadLiveChat, setLoadLiveChat] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => { 
      setLoadLiveChat(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  function handleNewEvent(event) {
    console.log('LiveChatWidget.onNewEvent', event);
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeTwo />} />

        <Route path={Navigate.homePage()} element={<HomeTwo />} />
        <Route path={Navigate.privacyPage()} element={<PrivacyPage />} />
        <Route path={Navigate.termsPage()} element={<TermsPage />} />
        <Route path={Navigate.contactPage()} element={<ContactPage />} />
        <Route path={Navigate.dataRemovalPage()} element={<DataRemovalPage />} />
        <Route path={Navigate.gdprPage()} element={<GDPRPage />} />
        <Route path={Navigate.accessDeniedPage()} element={<AccessDeniedPage />} />
        <Route path={Navigate.marketingPage()} element={<MarketingPage />} />
        <Route path={Navigate.revenuePage()} element={<RevenuePage />} />
        <Route path={Navigate.featuresPage()} element={<FeaturesPage />} />
        <Route path={Navigate.installationPage()} element={<InstallationPage />} />
        <Route path={Navigate.benefitsPage()} element={<BenefitsPage />} />
        <Route path={Navigate.creatorsPage()} element={<CreatorsPage />} />
        <Route path={Navigate.aboutPage()} element={<AboutPage />} />
        <Route path={Navigate.crawlPage()} element={<CrawlPage />} />

        <Route path={Navigate.creatorsInfluencersPage()} element={<CreatorsRewardsPage />} />
        <Route path={Navigate.creatorsOverviewPage()} element={<CreatorsOverviewPage />} />
        <Route path={Navigate.creatorsMicroPage()} element={<CreatorsMicroPage />} />
        <Route path={Navigate.creatorsBlackPage()} element={<CreatorsBlackPage />} />
        <Route path={Navigate.creatorsInfluencersMacPage()} element={<CreatorsInfluencerMacPage />} />
        <Route path={Navigate.creatorsInfluencersLinuxPage()} element={<CreatorsInfluencerLinuxPage />} />
        <Route path={Navigate.creatorsPublishersPage()} element={<CreatorsPublishersPage />} />
        <Route path={Navigate.creatorsPublishersMacPage()} element={<CreatorsPublishersMacPage />} />
        <Route path={Navigate.creatorsPublishersLinuxPage()} element={<CreatorsPublishersLinuxPage />} />
        <Route path={Navigate.creatorsCalculator()} element={<CreatorsCalculator />} />
        <Route path={Navigate.creatorsEditing()} element={<CreatorsEditingToolPage />} />
        <Route path={Navigate.creatorsFeatures()} element={<CreatorsFeaturesPage />} />
        <Route path={Navigate.creatorsMultistreamingPage()} element={<CreatorsMulticatingPage />} />
        <Route path={Navigate.creatorsTiktokstreamPage()} element={<CreatorsTiktokStreamPage />} />
        <Route path={Navigate.creatorsOptimizationPage()} element={<CreatorsOptimizationPage />} />
        <Route path={Navigate.creatorsGettingStartedPage()} element={<CreatorsGettingStarted />} />
        <Route path={Navigate.creatorsPlayTestersPage()} element={<CreatorsPlayTestersPage />} />


        <Route path={Navigate.creatorsOnboardingStep1Page()} element={<CreatorOnboardinStep1Page />} />
        <Route path={Navigate.creatorsOnboardingStep2Page()} element={<CreatorOnboardinStep2Page />} />
        <Route path={Navigate.creatorsOnboardingStep3Page()} element={<CreatorOnboardinStep3Page />} />
        <Route path={Navigate.creatorsOnboardingStep4Page()} element={<CreatorOnboardinStep4Page />} />
        <Route path={Navigate.creatorsOnboardingStep5Page()} element={<CreatorOnboardinStep5Page />} />

        <Route path={Navigate.campaignsPage()} element={<CampaignsListPage />} />
        <Route path={Navigate.campaignsCreatePage()} element={<CampaignCreatePage />} />
        <Route path={Navigate.campaignsUpdatePage()} element={<CampaignUpdatePage />} />
        <Route path={Navigate.campaignsViewPage()} element={<CampaignsViewPage />} />
        <Route path={Navigate.campaignsFindInfluencers()} element={<CampaignsFindInfluencersPage />} />
        <Route path={Navigate.campaignsInviteInfluencer()} element={<CampaignsInviteInfluencerPage />} />
        <Route path={Navigate.campaignsRecommendedInfluencers()} element={<CampaignsRecommendedInfluencersPage />} />
        <Route path={Navigate.campaignsResearchInfluencer()} element={<CampaignsViewCreatorPage />} />
        <Route path={Navigate.campaignsPerformanceInfluencer()} element={<CampaignsViewCreatorPerformancePage />} />
        <Route path={Navigate.campaignsContractsInfluencer()} element={<CampaignsViewCreatorContractPage />} />
        <Route path={Navigate.campaignsPaymentInfluencer()} element={<CampaignsViewCreatorPaymentPage />} />
        <Route path={Navigate.campaignsMessageInfluencer()} element={<CampaignsMessageInfluencerPage />} />
        <Route path={Navigate.campaignsStartPage()} element={<CampaignsStartPage />} />
        <Route path={Navigate.campaignsViewInfluencer()} element={<CampaignsViewInfluencerPage />} />
        <Route path={Navigate.campaignsInvitesPage()} element={<CampaignsInvitePage />} />
        <Route path={Navigate.campaignsInfluencersPage()} element={<CampaignsInfluencersPage />} />
        <Route path={Navigate.campaignsUpdateInfluencer()} element={<CampaignUpdateInfluencerPage />} />
        <Route path={Navigate.campaignsLedgerPage()} element={<CampaignsLedgerPage />} />
        <Route path={Navigate.campaignsContentPage()} element={<CampaignsContentPage />} />
        <Route path={Navigate.campaignsStatisticsPage()} element={<CampaignsStatisticsPage />} />
        <Route path={Navigate.campaignsUpdateInfluencerInviteCompensation()} element={<CampaignsUpdateInfluencerInviteCompensationPage />} />
        <Route path={Navigate.campaignsUpdateInfluencerInviteContract()} element={<CampaignsUpdateInfluencerInviteContractPage />} />
        <Route path={Navigate.campaignsUpdateMediaPage()} element={<CampaignUpdateMediaPage />} />
        <Route path={Navigate.campaignsUpdateLinksPage()} element={<CampaignUpdateLinksPage />} />
        <Route path={Navigate.campaignsViewInfluencerInvite()} element={<CampaignsViewInfluencerInvitePage />} />


        <Route path={Navigate.communitiesPage()} element={<CommunitiesList />} />
        <Route path={Navigate.communitiesAdminListPage()} element={<CommunitiesAdminList />} />
        <Route path={Navigate.communitiesCreatePage()} element={<CommunitiesCreate />} />
        <Route path={Navigate.communitiesViewPage()} element={<CommunitiesView />} />
        <Route path={Navigate.communitiesOverviewPage()} element={<CommunitiesManage />} />
        <Route path={Navigate.communitiesManagePage()} element={<CommunitiesManage />} />
        <Route path={Navigate.communitiesUpdatePage()} element={<CommunitiesUpdate />} />
        <Route path={Navigate.communitiesCnamePage()} element={<CommunitiesCname />} />
        <Route path={Navigate.communitiesFeaturesPage()} element={<CommunitiesFeatures />} />
        <Route path={Navigate.communitiesSocialPage()} element={<CommunitiesSocial />} />
        <Route path={Navigate.communitiesMediaPage()} element={<CommunitiesMedia />} />
        <Route path={Navigate.communitiesAccessibilityPage()} element={<CommunitiesAccessibility />} />
        <Route path={Navigate.communitiesNewsletterPage()} element={<CommunitiesNewsletter />} />
        <Route path={Navigate.communitiesCssPage()} element={<CommunitiesCss />} />
        <Route path={Navigate.communitiesUsersListPage()} element={<CommunitiesUsers />} />
        <Route path={Navigate.communitiesUsersInvitePage()} element={<CommunitiesUsersInvite />} />
        <Route path={Navigate.communitiesSubscribePage()} element={<PublisherSubscribePage />} />
        <Route path={Navigate.communitiesCardsPage()} element={< CommunitiesCreditCardsPage />} />
        <Route path={Navigate.communitiesContractstPage()} element={<CommunityContractsPage />} />
        <Route path={Navigate.communitiesEmailTemplatesPage()} element={<CommunitiesEmailTemplatesPage />} />
        <Route path={Navigate.communitiesGettingStartedPage()} element={<CommunitiesGettingStarted />} />
        <Route path={Navigate.communitiesUsersManagePage()} element={<CommunitiesUsersManage />} />



        <Route path={"/streams/:id/recording/:subid"} element={<WatchRecording />} />

        <Route path={Navigate.influencersListCampaignsPage()} element={<InfluencerCampaignsPage />} />
        <Route path={Navigate.influencersFindCampaignPage()} element={<InfluencerFindCampaignsPage />} />
        <Route path={Navigate.influencersViewCampaignPage()} element={<InfluencerViewCampaignPage />} />
        <Route path={Navigate.influencersManageCampaignPage()} element={<InfluencerManageCampaignPage />} />
        <Route path={Navigate.influencersMyCampaignsPage()} element={<InfluencerMyCampaignsPage />} />
        <Route path={Navigate.influencersCampaignInvitePage()} element={<InfluencerViewCampaignInvitePage />} />
        <Route path={Navigate.influencersPayoutsCampaignPage()} element={<InfluencerPayoutsPage />} />
        <Route path={Navigate.influencersBeginCampaignPage()} element={<InfluencerBeginCampaignPage />} />
        <Route path={Navigate.influencersApplyCampaignPage()} element={<InfluencerCampaignApplyPage />} />

        <Route path={Navigate.influencersMessagesCreatePage()} element={<InfluencerMessagesCreatePage />} />
        <Route path={Navigate.influencersMessagesListPage()} element={<InfluencerMessagesListPage />} />
        <Route path={Navigate.influencersMessagesThreadPage()} element={<InfluencerMessagesReadPage />} />

        <Route path={Navigate.influencersDownloadAppPage()} element={<InfluencerDownloadAppPage />} />
        <Route path={Navigate.influencersGettingStartedPage()} element={<InfluencerGettingStartedPage />} />

        <Route path={Navigate.influencersTutorials()} element={<InfluencerTutorialsPage />} />
        <Route path={Navigate.influencersTutorialStarting()} element={<InfluencerTutorialsContentPage />} />
        <Route path={Navigate.influencersTutorialInvites()} element={<InfluencerTutorialsInvitesPage />} />
        <Route path={Navigate.influencersTutorialGames()} element={<InfluencerTutorialsGamesPage />} />



        <Route path={Navigate.tipsEmojiOnly()} element={<EmojisPage />} />
        <Route path={Navigate.tipsEmojiAndWatch()} element={<EmojisWatchPage />} />

        <Route path={Navigate.authLogin()} element={<LogIn />} />
        <Route path={Navigate.authRegister()} element={<SignUp />} />
        <Route path={Navigate.accountRegisterStep2()} element={<RegisterStep2 />} />
        <Route path={Navigate.accountMainPage()} element={<AccountUpdatePage />} />
        <Route path={Navigate.accountVerify()} element={<AccountVerifyPage />} />
        
        <Route path={Navigate.authForgotPassword()} element={<ForgotPassword />} />
        <Route path={Navigate.authResetPassword()} element={<ResetPassword />} />

        <Route path={Navigate.authFacebookComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTwitchComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authGoogleComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authYoutubeComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTikTokComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authTwitterComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authRedditComplete()} element={<AuthComplete />} />
        <Route path={Navigate.authStripeComplete()} element={<AuthComplete />} />

        <Route path={Navigate.streamsCohostWatch()} element={<Cohostwatchstream />} />

        <Route path={Navigate.mediakitsPublishersListPage()} element={<MediakitListPage />} />
        <Route path={Navigate.mediakitsPublishersCreatePage()} element={<MediakitCreatePage />} />
        <Route path={Navigate.mediakitsPublishersUpdatePage()} element={<MediakitUpdatePage />} />
        <Route path={Navigate.mediakitsPublishersViewPage()} element={<MediakitViewPage />} />
        <Route path={Navigate.mediakitsCommunityStandalonePage()} element={<MediakitStandalonePage />} />
        <Route path={Navigate.mediakitsPublishersSharePage()} element={<MediakitSharePage />} />

        <Route path={Navigate.playtestingPublishersListPage()} element={<PlayTestGamesListPage />} />
        <Route path={Navigate.playtestingPublishersCreatePage()} element={<PlayTestGamesCreatePage />} />
        <Route path={Navigate.playtestingPublishersViewPage()} element={<PlayTestsListPage />} />
        <Route path={Navigate.playtestingPublishersViewTestPage()} element={<PlayTestDetailsPage />} />
        <Route path={Navigate.playtestingPublishersViewQuestionsPage()} element={<PlayTestManageQuestionsPage />} />
        <Route path={Navigate.playtestingPublishersInvitePage()} element={<PlayTestInviteTestersPage />} />
        <Route path={Navigate.playtestingPublishersUpdatePage()} element={<PlayTestGamesUpdatePage />} />
        <Route path={Navigate.playtestingPublishersViewReviewPage()} element={<PlayTestReviewTestPage />} />
        <Route path={Navigate.playtestingPublishersPreviewPage()} element={<PlayTestGamesPreviewPage />} />
        <Route path={Navigate.playtestingPublishersMessagesPage()} element={<PlayTestMessagePage />} />

        <Route path={Navigate.playtestingUserGames()} element={<PlayTestFindGamesToTestPage />} />
        <Route path={Navigate.playtestingUserTests()} element={<PlayTestMyPlayTestsPage />} />
        <Route path={Navigate.playtestingUserTest()} element={<PlayTestDetailsForTesterPage />} />
        <Route path={Navigate.playtestingUserAnswers()} element={<PlayTestSubmitPlayTestAnswersPage />} />
        <Route path={Navigate.playtestingUserGame()} element={<PlayTestGameDetailsPage />} />



        <Route path={Navigate.messagesListPage()} element={<MessagesListPage />} />
        <Route path={Navigate.messagesCreatePage()} element={<MessagesCreatePage />} />
        <Route path={Navigate.messagesReadPage()} element={<MessagesReadPage />} />
        <Route path={Navigate.messagesThreadPage()} element={<MessagesReadPage />} />

        <Route path={Navigate.gameshowPublisherListPage()} element={<GameShowsListPage />} />
        <Route path={Navigate.gameshowPublisherCreatePage()} element={<GameShowsCreatePage />} />
        <Route path={Navigate.gameshowPublisherViewPage()} element={<GameShowsViewPage />} />
        <Route path={Navigate.gameshowPublisherUpdatePage()} element={<GameShowsUpdatePage />} />
        <Route path={Navigate.gameshowPublisherPromotePage()} element={<GameShowsPromotePage />} />

        <Route path={Navigate.publishersGamificationPage()} element={<PublishersGamificationPage />} />
        <Route path={Navigate.publishersOptimizationPage()} element={<PublishersOptimizationPage />} />
        <Route path={Navigate.publishersBenefitsPage()} element={<PublisherBenefitPage />} />
        <Route path={Navigate.publishersAcquisitionPage()} element={<PublisherUserAcquistion />} />
        <Route path={Navigate.publishersRegisterPage()} element={<PublisherRegisterPage />} />
        <Route path={Navigate.publishersPilotPage()} element={<PublisherPilotsPage />} />
        <Route path={Navigate.publishersPricingPage()} element={<PublisherPricingPage />} />
        <Route path={Navigate.publishersDatabasePage()} element={<PublishersDatabasePage />} />
        <Route path={Navigate.publishersAIPage()} element={<PublishersAIPage />} />
        <Route path={Navigate.publishersCRMPage()} element={<PublishersCRMPage />} />
        <Route path={Navigate.publishersContentPage()} element={<PublishersContentPage />} />
        <Route path={Navigate.publishersHoldaysPage()} element={<PublishersHolidayPage />} />
        <Route path={Navigate.publishersPlayTesterPage()} element={<PublishersPlayersPage />} />
        <Route path={Navigate.publishersNewsletterPage()} element={<PublishersNewsletterPage />} />
        <Route path={Navigate.publishersShowcasesPage()} element={<PublishersShowcasesPage />} />
        <Route path={Navigate.publishersMediakitPage()} element={<PublishersMediaKitPage />} />


        <Route path={Navigate.publishersGamificationPage2()} element={<PublishersGamificationPage />} />
        <Route path={Navigate.publishersOptimizationPage2()} element={<PublishersOptimizationPage />} />
        <Route path={Navigate.publishersBenefitsPage2()} element={<PublisherBenefitPage />} />
        <Route path={Navigate.publishersAcquisitionPage2()} element={<PublisherUserAcquistion />} />
        <Route path={Navigate.publishersDatabasePage2()} element={<PublishersDatabasePage />} />
        <Route path={Navigate.publishersAIPage2()} element={<PublishersAIPage />} />
        <Route path={Navigate.publishersCRMPage2()} element={<PublishersCRMPage />} />
    

        <Route path={Navigate.publishersToolsPage()} element={<PublisherGameFreeToolsPage />} />
        <Route path={Navigate.publishersToolsForumPage()} element={<PublisherGameForumsPage />} />
        <Route path={Navigate.publishersToolsWikiPage()} element={<PublisherGameWikiPage />} />
        <Route path={Navigate.publishersToolsDatabasePage()} element={<PublisherGamDatabasePage />} />
        <Route path={Navigate.publishersToolsPricingPage()} element={<PublisherGamePricingPage />} />
        <Route path={Navigate.publishersToolsPRPage()} element={<PublisherGamePRPage />} />
        <Route path={Navigate.publishersToolsInfluencersPage()} element={<PublisherGameInfluencersPage />} />
        <Route path={Navigate.publishersToolsShowcasesPage()} element={<PublisherGameShowsPage />} />
        <Route path={Navigate.publishersToolsGuidePage()} element={<PublisherGameGuidePage />} />
        <Route path={Navigate.publishersToolsEmailPage()} element={<PublisherGameNewsletterPage />} />

        <Route path={Navigate.publishersOnboardingStep1Page()} element={<PublisherOnboardinStep1Page />} />
        <Route path={Navigate.publishersOnboardingStep2Page()} element={<PublisherOnboardinStep2Page />} />
        <Route path={Navigate.publishersOnboardingStep3Page()} element={<PublisherOnboardingStep3Page />} />
        <Route path={Navigate.publishersOnboardingStep4Page()} element={<PublisherOnboardingStep4Page />} />

        <Route path={Navigate.publishersMessagesCreatePage()} element={<PublisherMessagesCreatePage />} />
        <Route path={Navigate.publishersMessagesListPage()} element={<PublisherMessagesListPage />} />
        <Route path={Navigate.publishersMessagesThreadPage()} element={<PublisherMessagesReadPage />} />

        <Route path={Navigate.publishersTutorialsPage()} element={<PublisherTutorialsPage/>} />

        <Route path={Navigate.publishersTutorialsInvitesPipelinesPage()} element={<PublisherTutorialInvitePipelinesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesDatabasePage()} element={<PublisherTutorialInviteDatabasePage />} />
        <Route path={Navigate.publishersTutorialsInvitesRecommendedPage()} element={<PublisherTutorialInviteRecommendedPage />} />
        <Route path={Navigate.publishersTutorialsInvitesInvitePage()} element={<PublisherTutorialInvitesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesCustomPage()} element={<PublisherTutorialInviteCustomEmailsPage />} />
        <Route path={Navigate.publishersTutorialsInvitesNotesPage()} element={<PublisherTutorialInviteNotesPage />} />
        <Route path={Navigate.publishersTutorialsInvitesNegotiationsPage()} element={<PublisherTutorialInviteNegotiationsPage />} />

        <Route path={Navigate.publishersTutorialsCampaignsOverviewPage()} element={<PublisherTutorialCampaignsOverviewPage />} />
        <Route path={Navigate.publishersTutorialsCampaignsCreatePage()} element={<PublisherTutorialCampaignsCreatePage />} />
        <Route path={Navigate.publishersTutorialsCampaignsChecklistPage()} element={<PublisherTutorialCampaignsChecklistPage />} />
        <Route path={Navigate.publishersTutorialsCampaignsRatecardPage()} element={<PublisherTutorialCampaignsRateCardPage />} />

        <Route path={Navigate.schedulerListPage()} element={<SchedulerListPage />} />
        <Route path={Navigate.schedulerCreatePage()} element={<SchedulerCreatePage />} />
        <Route path={Navigate.schedulerSocialsPage()} element={<SchedulerSocialPage />} />
        <Route path={Navigate.schedulerUpdatePage()} element={<SchedulerUpdatePage />} />
        <Route path={Navigate.schedulerViewPage()} element={<SchedulerViewPage />} />
        <Route path={Navigate.schedulerUpdatesListPage()} element={<SchedulerListUpdatesPage />} />
        <Route path={Navigate.schedulerPostsPage()} element={<SchedulerListSocialMediaPostsPage />} />


         
        <Route path={Navigate.schedulerUpdatesCreatePage()} element={<SchedulerCreateUpdatePage />} />
        <Route path={Navigate.schedulerUpdatesUpdatePage()} element={<SchedulerUpdateUpdatePage />} />

        <Route path={Navigate.newsletterListPage()} element={<NewslettersListPage />} />
        <Route path={Navigate.newsletterCreatePage()} element={<NewslettersCreatePage />} />
        <Route path={Navigate.newsletterViewPage()} element={<NewsletterViewPage />} />
        <Route path={Navigate.newsletterUpdatePage()} element={<NewsletterUpdatePage />} />
        <Route path={Navigate.newsletterSubscribersPage()} element={<NewsletterSubscribersPage />} />
        <Route path={Navigate.newsletterSubscribePage()} element={<NewsletterSubscribePage/>} />
        <Route path={Navigate.newsletterImportPage()} element={<NewsletterImportPage/>} />

        <Route path={Navigate.newsletterCampaignCreatePage()} element={<NewsletterCampaignCreatePage />} />
        <Route path={Navigate.newsletterCampaignSendPage()} element={<NewsletterCampaignSendPage />} />
        <Route path={Navigate.newsletterCampaignUpdatePage()} element={<NewsletterCampaignUpdatePage />} />
        <Route path={Navigate.newsletterCampaignViewPage()} element={<NewsletterCampaignViewPage />} />


        <Route path={Navigate.usersInvitesPage()} element={<InfluencerInvitesPage />} />
        <Route path={Navigate.usersPayoutsPage()} element={<InfluencerPayoutsPage />} />

        <Route path={Navigate.authTwitch()} element={<AuthTwitch />} />
        <Route path={Navigate.authTikTok()} element={<AuthTikTok />} />
        <Route path={Navigate.authFacebook()} element={<AuthFacebook />} />
        <Route path={Navigate.authGoogle()} element={<AuthGoogle />} />
        <Route path={Navigate.authTwitter()} element={<AuthTwitter />} />
        <Route path={Navigate.authReddit()} element={<AuthReddit />} />
        <Route path={Navigate.authStripe()} element={<AuthStripe />} />
        <Route path={Navigate.authDocusign()} element={<AuthDocusign />} />
        <Route path={Navigate.authSimpleSign()} element={<AuthSimplesign />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {loadLiveChat && (
        <Suspense fallback={<div>Loading chat...</div>}>
          <LiveChatWidget
            license="18255615"
            visibility="minimized"
            onNewEvent={handleNewEvent}
          />
        </Suspense>
      )}
    </>
  );
}

export default Template;
