// src/pages/scheduler/scheduler_create_update_page.jsx

import React, { useEffect, useState } from 'react';
import TitleUpdateForm from '../../component/section/updates/title_update_form';
import { Link, useParams } from 'react-router-dom';
import Glitch from 'glitch-javascript-sdk';
import Loading from '../../component/alerts/Loading';
import timeouts from '../../../../constants/timeouts';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Navigate from '../../../../util/Navigate';

const SchedulerCreateUpdatePage = () => {
    const { id } = useParams();
    const [updateData, setUpdateData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [schedulerData, setSchedulerData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const fetchSchedulerData = async () => {
            try {
                const response = await Glitch.api.Scheduler.getSchedule(id);
                setSchedulerData(response.data.data);
            } catch (error) {
                console.error('Error fetching scheduler data:', error);
                // Handle error, maybe redirect or show message
            }
        };
        fetchSchedulerData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await Glitch.api.Scheduler.createUpdate(id, updateData);
            setIsSuccess(true); // Show success message
        } catch (error) {
            console.error('Error creating update:', error);
            let jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: schedulerData?.name, link: Navigate.schedulerViewPage(schedulerData?.id) },
                    { name: 'Updates', link: Navigate.schedulerUpdatesListPage(schedulerData?.id) },
                    { name: 'Create Update', link: Navigate.schedulerUpdatesCreatePage(schedulerData?.id) },
                ]}
                />
            </div>
            {isSuccess ? (<></>

            ) : (
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title mt-3">Submit An Update</h2>
                            <p className="lead">Create a new update for {schedulerData?.title?.name} that will be turned into social media content.</p>
                        </div>
                    </div>
                </section>
            )};

            <div className="container ">
                {isSuccess ? (
                    <div className="text-center mb-5 mt-5">
                        <h2 className="text-success">Thank you for your update!</h2>
                        <p className="lead">It will be turned into social media content and scheduled soon.</p>
                        <div>
                            <Link className='btn btn-success btn-lg' to={Navigate.schedulerUpdatesListPage(schedulerData?.id)}><i className="fas fa-undo-alt"></i> Back To Updates</Link>
                        </div>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <TitleUpdateForm
                            id={id}
                            updateData={updateData}
                            setUpdateData={setUpdateData}
                            errors={errors}
                            isEditMode={false}
                            schedulerData={schedulerData}
                        />

                        <div className="mt-4 text-center">
                            {Object.keys(errors).length > 0 && Object.keys(errors).map((errorKey) => (
                                errors[errorKey].map((message, index) => (
                                    <Danger key={`${errorKey}-${index}`} message={message} />
                                ))
                            ))}
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary btn-lg">
                                {isLoading ? <Loading /> : 'Create Update'}
                            </button>
                        </div>
                    </form>
                )}
            </div>

            <Footer />
        </>
    );
};

export default SchedulerCreateUpdatePage;
