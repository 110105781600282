// src/component/section/socialmedia/SocialMediaPostItem.jsx

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Navigate from '../../../../../util/Navigate';


const SocialMediaPostItem = ({ post, currentUserId }) => {
    const platformIcons = {
        facebook: faFacebook,
        instagram: faInstagram,
        twitter: faTwitter,
        tiktok: faTiktok,
        discord: faDiscord,
        reddit: faReddit,
        youtube: faYoutube,
        telegram: faTelegram,
        bluesky: faKickstarter, // Placeholder icon
    };

    const mediaUrl = post.media && post.media.length > 0 ? post.media[0].url : null;

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>
                    <h5>{post.title || 'Untitled Post'}</h5>
                    <small className="text-muted">{post.scheduled_at ? `Scheduled for ${new Date(post.scheduled_at).toLocaleString()}` : 'Not Scheduled'}</small>
                </div>
                {currentUserId === post.user_id && (
                    <Link to={Navigate.socialMediaPostEditPage(post.id)} className="btn btn-sm btn-secondary">
                        Edit
                    </Link>
                )}
            </div>
            <div className="card-body">
                {post.content && (
                    <p>{post.content}</p>
                )}
                {mediaUrl && (
                    <div className="mt-3">
                        {post.media[0].mime_type && post.media[0].mime_type.startsWith('video/') ? (
                            <video controls style={{ maxWidth: '100%' }}>
                                <source src={mediaUrl} type={post.media[0].mime_type} />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={mediaUrl} alt="Post Media" style={{ maxWidth: '100%' }} />
                        )}
                    </div>
                )}
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                    {post.user && (
                        <small className="text-muted">
                            Posted by {post.user.username}
                        </small>
                    )}
                </div>
                <div>
                    {post.social_platform && (
                        <FontAwesomeIcon
                            icon={platformIcons[post.social_platform]}
                            title={post.social_platform}
                            className="ml-2"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SocialMediaPostItem;
